const Error404Banner: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  viewBox?: string
}> = ({ width = '235', height = '141', viewBox = '0 0 235 141' }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.26184 88.105V74.6839L28.8355 36.2102H50.4882L24.8987 74.6839L15.0566 71.7313H63.5513V88.105H2.26184ZM34.7408 98.8418V88.105L35.3671 71.7313V62.1576H55.2303V98.8418H34.7408Z"
      fill="url(#paint0_linear_978_44060)"
    />
    <path
      d="M173.156 88.105V74.6839L199.73 36.2102H221.383L195.793 74.6839L185.951 71.7313H234.446V88.105H173.156ZM205.635 98.8418V88.105L206.262 71.7313V62.1576H226.125V98.8418H205.635Z"
      fill="url(#paint1_linear_978_44060)"
    />
    <path
      d="M113.091 105.671C132.76 110.099 152.295 97.7433 156.723 78.0737C161.151 58.4041 148.795 38.8692 129.126 34.4413C109.456 30.0134 89.9212 42.3692 85.4933 62.0388C81.0654 81.7084 93.4213 101.243 113.091 105.671Z"
      fill="url(#paint2_linear_978_44060)"
    />
    <path
      d="M140.016 38.7997C139.52 38.5348 139.08 38.1966 138.585 37.9316L138.021 38.6641C137.739 39.0303 137.807 39.5488 138.173 39.8306C138.539 40.1124 139.058 40.0449 139.339 39.6786L140.016 38.7997Z"
      fill="#1D2228"
    />
    <path
      d="M137.707 41.8017C137.988 41.4354 137.921 40.917 137.555 40.6352C137.188 40.3534 136.67 40.4209 136.388 40.7871L134.81 42.838C134.528 43.2043 134.596 43.7227 134.962 44.0045C135.328 44.2863 135.847 44.2188 136.129 43.8526L137.707 41.8017Z"
      fill="#1D2228"
    />
    <path
      d="M125.023 58.2773C125.305 57.911 125.237 57.3926 124.871 57.1108C124.505 56.829 123.986 56.8965 123.705 57.2627L122.126 59.3136C121.845 59.6799 121.912 60.1983 122.278 60.4801C122.645 60.7619 123.163 60.6944 123.445 60.3281L125.023 58.2773Z"
      fill="#1D2228"
    />
    <path
      d="M118.656 66.5614C118.938 66.1952 118.87 65.6768 118.504 65.395C118.138 65.1131 117.619 65.1807 117.337 65.5469L115.703 67.671C115.421 68.0373 115.489 68.5557 115.855 68.8375C116.221 69.1193 116.74 69.0518 117.021 68.6856L118.656 66.5614Z"
      fill="#1D2228"
    />
    <path
      d="M121.818 62.454C122.1 62.0878 122.032 61.5694 121.666 61.2875C121.3 61.0057 120.781 61.0733 120.5 61.4395L118.921 63.4904C118.64 63.8566 118.707 64.375 119.073 64.6569C119.44 64.9387 119.958 64.8711 120.24 64.5049L121.818 62.454Z"
      fill="#1D2228"
    />
    <path
      d="M131.399 49.9999C131.681 49.6337 131.613 49.1152 131.247 48.8334C130.881 48.5516 130.362 48.6192 130.081 48.9854L128.502 51.0363C128.221 51.4025 128.288 51.9209 128.654 52.2028C129.021 52.4846 129.539 52.417 129.821 52.0508L131.399 49.9999Z"
      fill="#1D2228"
    />
    <path
      d="M134.56 45.9003C134.842 45.5341 134.774 45.0156 134.408 44.7338C134.042 44.452 133.524 44.5195 133.242 44.8858L131.664 46.9367C131.382 47.3029 131.449 47.8213 131.816 48.1031C132.182 48.385 132.7 48.3174 132.982 47.9512L134.56 45.9003Z"
      fill="#1D2228"
    />
    <path
      d="M128.184 54.1806C128.466 53.8143 128.398 53.2959 128.032 53.0141C127.666 52.7323 127.148 52.7998 126.866 53.1661L125.288 55.2169C125.006 55.5832 125.073 56.1016 125.44 56.3834C125.806 56.6652 126.324 56.5977 126.606 56.2315L128.184 54.1806Z"
      fill="#1D2228"
    />
    <path
      d="M115.449 70.7323C115.731 70.3661 115.663 69.8477 115.297 69.5659C114.931 69.284 114.412 69.3516 114.13 69.7178L112.552 71.7687C112.27 72.1349 112.338 72.6534 112.704 72.9352C113.07 73.217 113.589 73.1494 113.871 72.7832L115.449 70.7323Z"
      fill="#1D2228"
    />
    <path
      d="M99.4004 90.2231C99.0342 89.9413 98.5158 90.0088 98.2339 90.375L96.6558 92.4259C96.374 92.7922 96.4415 93.3106 96.8077 93.5924C97.174 93.8742 97.6924 93.8067 97.9742 93.4405L99.5524 91.3896C99.8342 91.0233 99.7667 90.5049 99.4004 90.2231Z"
      fill="#1D2228"
    />
    <path
      d="M105.928 83.1122C106.21 82.746 106.143 82.2276 105.776 81.9457C105.41 81.6639 104.892 81.7315 104.61 82.0977L103.032 84.1486C102.75 84.5148 102.817 85.0332 103.184 85.3151C103.55 85.5969 104.068 85.5293 104.35 85.1631L105.928 83.1122Z"
      fill="#1D2228"
    />
    <path
      d="M109.08 79.0097C109.362 78.6434 109.294 78.125 108.928 77.8432C108.562 77.5614 108.043 77.6289 107.761 77.9952L106.183 80.046C105.901 80.4123 105.969 80.9307 106.335 81.2125C106.701 81.4943 107.22 81.4268 107.502 81.0606L109.08 79.0097Z"
      fill="#1D2228"
    />
    <path
      d="M112.296 74.8339C112.577 74.4677 112.51 73.9492 112.144 73.6674C111.777 73.3856 111.259 73.4531 110.977 73.8194L109.399 75.8703C109.117 76.2365 109.185 76.7549 109.551 77.0367C109.917 77.3186 110.436 77.251 110.717 76.8848L112.296 74.8339Z"
      fill="#1D2228"
    />
    <path
      d="M102.714 87.286C102.995 86.9198 102.928 86.4014 102.562 86.1196C102.195 85.8378 101.677 85.9053 101.395 86.2715L99.8169 88.3224C99.5351 88.6886 99.6026 89.2071 99.9689 89.4889C100.335 89.7707 100.854 89.7032 101.135 89.3369L102.714 87.286Z"
      fill="#1D2228"
    />
    <path
      d="M96.3981 95.4911C96.6799 95.1249 96.6124 94.6065 96.2461 94.3246C95.8799 94.0428 95.3615 94.1104 95.0797 94.4766L94.6287 95.0626C95.0119 95.474 95.395 95.8854 95.8345 96.2236L96.3981 95.4911Z"
      fill="#1D2228"
    />
    <path
      d="M137.046 37.2089C136.55 36.9439 135.998 36.7522 135.502 36.4873L135.389 36.6338C135.108 37 135.175 37.5185 135.541 37.8003C135.908 38.0821 136.426 38.0146 136.708 37.6483L137.046 37.2089Z"
      fill="#1D2228"
    />
    <path
      d="M128.767 47.9784C129.049 47.6122 128.981 47.0938 128.615 46.812C128.249 46.5301 127.731 46.5977 127.449 46.9639L125.871 49.0148C125.589 49.381 125.656 49.8995 126.023 50.1813C126.389 50.4631 126.907 50.3955 127.189 50.0293L128.767 47.9784Z"
      fill="#1D2228"
    />
    <path
      d="M100.082 85.2587C100.363 84.8925 100.296 84.374 99.9297 84.0922C99.5635 83.8104 99.0451 83.8779 98.7632 84.2442L97.1851 86.2951C96.9033 86.6613 96.9708 87.1797 97.337 87.4615C97.7033 87.7434 98.2217 87.6758 98.5035 87.3096L100.082 85.2587Z"
      fill="#1D2228"
    />
    <path
      d="M119.185 60.4276C119.467 60.0614 119.399 59.543 119.033 59.2612C118.667 58.9794 118.149 59.0469 117.867 59.4131L116.289 61.464C116.007 61.8302 116.074 62.3487 116.441 62.6305C116.807 62.9123 117.325 62.8448 117.607 62.4785L119.185 60.4276Z"
      fill="#1D2228"
    />
    <path
      d="M96.7676 88.1948C96.4014 87.913 95.8829 87.9805 95.6011 88.3467L94.023 90.3976C93.7412 90.7638 93.8087 91.2823 94.1749 91.5641C94.5412 91.8459 95.0596 91.7784 95.3414 91.4121L96.9196 89.3612C97.2014 88.995 97.1338 88.4766 96.7676 88.1948Z"
      fill="#1D2228"
    />
    <path
      d="M106.443 76.9823C106.725 76.6161 106.657 76.0977 106.291 75.8159C105.925 75.534 105.406 75.6016 105.125 75.9678L103.546 78.0187C103.265 78.3849 103.332 78.9034 103.698 79.1852C104.065 79.467 104.583 79.3995 104.865 79.0332L106.443 76.9823Z"
      fill="#1D2228"
    />
    <path
      d="M103.289 81.081C103.571 80.7147 103.503 80.1963 103.137 79.9145C102.771 79.6327 102.252 79.7002 101.97 80.0664L100.392 82.1173C100.11 82.4836 100.178 83.002 100.544 83.2838C100.91 83.5656 101.429 83.4981 101.711 83.1319L103.289 81.081Z"
      fill="#1D2228"
    />
    <path
      d="M109.655 72.8066C109.937 72.4403 109.869 71.9219 109.503 71.6401C109.137 71.3583 108.618 71.4258 108.336 71.792L106.758 73.8429C106.477 74.2091 106.544 74.7276 106.91 75.0094C107.277 75.2912 107.795 75.2237 108.077 74.8574L109.655 72.8066Z"
      fill="#1D2228"
    />
    <path
      d="M122.401 56.2519C122.683 55.8856 122.615 55.3672 122.249 55.0854C121.883 54.8036 121.364 54.8711 121.083 55.2373L119.504 57.2882C119.223 57.6545 119.29 58.1729 119.656 58.4547C120.023 58.7365 120.541 58.669 120.823 58.3028L122.401 56.2519Z"
      fill="#1D2228"
    />
    <path
      d="M112.817 68.703C113.099 68.3368 113.031 67.8184 112.665 67.5366C112.299 67.2547 111.78 67.3223 111.499 67.6885L109.92 69.7394C109.639 70.1056 109.706 70.6241 110.072 70.9059C110.439 71.1877 110.957 71.1202 111.239 70.7539L112.817 68.703Z"
      fill="#1D2228"
    />
    <path
      d="M116.024 64.5292C116.306 64.163 116.238 63.6445 115.872 63.3627C115.506 63.0809 114.987 63.1485 114.706 63.5147L113.071 65.6388C112.789 66.005 112.857 66.5235 113.223 66.8053C113.589 67.0871 114.108 67.0196 114.39 66.6533L116.024 64.5292Z"
      fill="#1D2228"
    />
    <path
      d="M125.552 52.1493C125.834 51.7831 125.767 51.2647 125.4 50.9828C125.034 50.701 124.516 50.7686 124.234 51.1348L122.656 53.1857C122.374 53.5519 122.442 54.0704 122.808 54.3522C123.174 54.634 123.692 54.5664 123.974 54.2002L125.552 52.1493Z"
      fill="#1D2228"
    />
    <path
      d="M135.075 39.7704C135.357 39.4042 135.289 38.8858 134.923 38.6039C134.557 38.3221 134.038 38.3897 133.756 38.7559L132.178 40.8068C131.896 41.173 131.964 41.6914 132.33 41.9733C132.696 42.2551 133.215 42.1875 133.497 41.8213L135.075 39.7704Z"
      fill="#1D2228"
    />
    <path
      d="M131.921 43.8739C132.202 43.5077 132.135 42.9893 131.769 42.7075C131.402 42.4256 130.884 42.4932 130.602 42.8594L129.024 44.9103C128.742 45.2765 128.81 45.795 129.176 46.0768C129.542 46.3586 130.061 46.2911 130.342 45.9248L131.921 43.8739Z"
      fill="#1D2228"
    />
    <path
      d="M93.4307 93.8984L93.7125 93.5321C93.9943 93.1659 93.9268 92.6475 93.5606 92.3657C93.1944 92.0838 92.6759 92.1514 92.3941 92.5176L92.3377 92.5909C92.7209 93.0023 93.104 93.4137 93.4307 93.8984Z"
      fill="#1D2228"
    />
    <path
      d="M148.535 45.9287L148.141 46.4414C147.859 46.8077 147.926 47.3261 148.293 47.6079C148.659 47.8897 149.177 47.8222 149.459 47.456L149.628 47.2362C149.245 46.8248 148.862 46.4134 148.535 45.9287Z"
      fill="#1D2228"
    />
    <path
      d="M109.511 98.0004C109.145 97.7186 108.626 97.7861 108.344 98.1524L106.766 100.203C106.484 100.57 106.552 101.088 106.918 101.37C107.284 101.652 107.803 101.584 108.085 101.218L109.663 99.1669C109.945 98.8007 109.877 98.2822 109.511 98.0004Z"
      fill="#1D2228"
    />
    <path
      d="M131.928 70.2362C132.21 69.87 132.143 69.3516 131.776 69.0698C131.41 68.788 130.892 68.8555 130.61 69.2217L129.032 71.2726C128.75 71.6388 128.817 72.1573 129.184 72.4391C129.55 72.7209 130.068 72.6534 130.35 72.2871L131.928 70.2362Z"
      fill="#1D2228"
    />
    <path
      d="M135.144 66.0624C135.426 65.6962 135.358 65.1778 134.992 64.8959C134.626 64.6141 134.108 64.6817 133.826 65.0479L132.248 67.0988C131.966 67.465 132.033 67.9834 132.4 68.2653C132.766 68.5471 133.284 68.4795 133.566 68.1133L135.144 66.0624Z"
      fill="#1D2228"
    />
    <path
      d="M147.826 49.5761C148.108 49.2099 148.04 48.6914 147.674 48.4096C147.308 48.1278 146.789 48.1953 146.507 48.5616L144.929 50.6124C144.647 50.9787 144.715 51.4971 145.081 51.7789C145.447 52.0607 145.966 51.9932 146.248 51.627L147.826 49.5761Z"
      fill="#1D2228"
    />
    <path
      d="M138.29 61.9579C138.571 61.5917 138.504 61.0733 138.138 60.7914C137.772 60.5096 137.253 60.5772 136.971 60.9434L135.393 62.9943C135.111 63.3605 135.179 63.8789 135.545 64.1608C135.911 64.4426 136.43 64.375 136.712 64.0088L138.29 61.9579Z"
      fill="#1D2228"
    />
    <path
      d="M144.656 53.6796C144.938 53.3134 144.87 52.7949 144.504 52.5131C144.138 52.2313 143.619 52.2988 143.337 52.6651L141.759 54.716C141.477 55.0822 141.545 55.6006 141.911 55.8824C142.277 56.1643 142.796 56.0967 143.078 55.7305L144.656 53.6796Z"
      fill="#1D2228"
    />
    <path
      d="M141.506 57.7812C141.787 57.4149 141.72 56.8965 141.354 56.6147C140.987 56.3329 140.469 56.4004 140.187 56.7666L138.609 58.8175C138.327 59.1838 138.395 59.7022 138.761 59.984C139.127 60.2658 139.646 60.1983 139.927 59.8321L141.506 57.7812Z"
      fill="#1D2228"
    />
    <path
      d="M125.562 78.5058C125.844 78.1395 125.776 77.6211 125.41 77.3393C125.044 77.0575 124.526 77.125 124.244 77.4912L122.666 79.5421C122.384 79.9084 122.451 80.4268 122.818 80.7086C123.184 80.9904 123.702 80.9229 123.984 80.5567L125.562 78.5058Z"
      fill="#1D2228"
    />
    <path
      d="M128.759 74.3388C129.041 73.9726 128.974 73.4541 128.607 73.1723C128.241 72.8905 127.723 72.958 127.441 73.3243L125.806 75.4484C125.525 75.8146 125.592 76.3331 125.958 76.6149C126.325 76.8967 126.843 76.8291 127.125 76.4629L128.759 74.3388Z"
      fill="#1D2228"
    />
    <path
      d="M116.032 90.8886C116.314 90.5224 116.246 90.0039 115.88 89.7221C115.514 89.4403 114.995 89.5078 114.713 89.8741L113.135 91.9249C112.853 92.2912 112.921 92.8096 113.287 93.0914C113.653 93.3732 114.172 93.3057 114.454 92.9395L116.032 90.8886Z"
      fill="#1D2228"
    />
    <path
      d="M122.393 82.6132C122.675 82.247 122.607 81.7285 122.241 81.4467C121.875 81.1649 121.357 81.2324 121.075 81.5987L119.497 83.6496C119.215 84.0158 119.282 84.5342 119.649 84.816C120.015 85.0978 120.533 85.0303 120.815 84.6641L122.393 82.6132Z"
      fill="#1D2228"
    />
    <path
      d="M112.817 95.0605C113.099 94.6942 113.031 94.1758 112.665 93.894C112.299 93.6122 111.78 93.6797 111.499 94.0459L109.92 96.0968C109.639 96.463 109.706 96.9815 110.072 97.2633C110.439 97.5451 110.957 97.4776 111.239 97.1114L112.817 95.0605Z"
      fill="#1D2228"
    />
    <path
      d="M119.186 86.79C119.468 86.4237 119.4 85.9053 119.034 85.6235C118.668 85.3417 118.15 85.4092 117.868 85.7754L116.29 87.8263C116.008 88.1925 116.075 88.711 116.442 88.9928C116.808 89.2746 117.326 89.2071 117.608 88.8408L119.186 86.79Z"
      fill="#1D2228"
    />
    <path
      d="M106.497 103.268C106.779 102.902 106.711 102.384 106.345 102.102C105.979 101.82 105.46 101.888 105.178 102.254L104.84 102.693C105.336 102.958 105.888 103.15 106.384 103.415L106.497 103.268Z"
      fill="#1D2228"
    />
    <path
      d="M147.378 44.6913C146.994 44.2799 146.611 43.8684 146.172 43.5303L145.496 44.4092C145.214 44.7755 145.281 45.2939 145.647 45.5757C146.014 45.8575 146.532 45.79 146.814 45.4238L147.378 44.6913Z"
      fill="#1D2228"
    />
    <path
      d="M129.282 68.2089C129.564 67.8427 129.496 67.3242 129.13 67.0424C128.764 66.7606 128.245 66.8281 127.963 67.1944L126.385 69.2453C126.103 69.6115 126.171 70.1299 126.537 70.4117C126.903 70.6936 127.422 70.626 127.704 70.2598L129.282 68.2089Z"
      fill="#1D2228"
    />
    <path
      d="M126.131 72.3105C126.413 71.9442 126.346 71.4258 125.98 71.144C125.613 70.8622 125.095 70.9297 124.813 71.2959L123.179 73.4201C122.897 73.7863 122.964 74.3047 123.33 74.5865C123.697 74.8684 124.215 74.8008 124.497 74.4346L126.131 72.3105Z"
      fill="#1D2228"
    />
    <path
      d="M132.498 64.0302C132.78 63.664 132.712 63.1455 132.346 62.8637C131.98 62.5819 131.461 62.6494 131.179 63.0157L129.601 65.0665C129.319 65.4328 129.387 65.9512 129.753 66.233C130.119 66.5148 130.638 66.4473 130.92 66.0811L132.498 64.0302Z"
      fill="#1D2228"
    />
    <path
      d="M142.028 51.6552C142.31 51.289 142.242 50.7705 141.876 50.4887C141.51 50.2069 140.991 50.2744 140.71 50.6407L139.131 52.6915C138.85 53.0578 138.917 53.5762 139.283 53.858C139.65 54.1398 140.168 54.0723 140.45 53.7061L142.028 51.6552Z"
      fill="#1D2228"
    />
    <path
      d="M119.762 80.5839C120.044 80.2177 119.977 79.6992 119.61 79.4174C119.244 79.1356 118.726 79.2031 118.444 79.5694L116.866 81.6203C116.584 81.9865 116.651 82.5049 117.018 82.7867C117.384 83.0686 117.902 83.001 118.184 82.6348L119.762 80.5839Z"
      fill="#1D2228"
    />
    <path
      d="M145.181 47.5497C145.463 47.1835 145.396 46.6651 145.029 46.3832C144.663 46.1014 144.145 46.169 143.863 46.5352L142.285 48.5861C142.003 48.9523 142.07 49.4707 142.437 49.7526C142.803 50.0344 143.321 49.9668 143.603 49.6006L145.181 47.5497Z"
      fill="#1D2228"
    />
    <path
      d="M135.651 59.9286C135.933 59.5624 135.865 59.044 135.499 58.7621C135.133 58.4803 134.614 58.5479 134.333 58.9141L132.754 60.965C132.473 61.3312 132.54 61.8497 132.906 62.1315C133.273 62.4133 133.791 62.3457 134.073 61.9795L135.651 59.9286Z"
      fill="#1D2228"
    />
    <path
      d="M138.874 55.7548C139.155 55.3886 139.088 54.8701 138.722 54.5883C138.355 54.3065 137.837 54.374 137.555 54.7403L135.977 56.7912C135.695 57.1574 135.763 57.6758 136.129 57.9576C136.495 58.2395 137.014 58.1719 137.296 57.8057L138.874 55.7548Z"
      fill="#1D2228"
    />
    <path
      d="M122.916 76.4823C123.197 76.1161 123.13 75.5977 122.764 75.3159C122.397 75.034 121.879 75.1016 121.597 75.4678L120.019 77.5187C119.737 77.8849 119.805 78.4034 120.171 78.6852C120.537 78.967 121.056 78.8994 121.338 78.5332L122.916 76.4823Z"
      fill="#1D2228"
    />
    <path
      d="M110.172 93.037C110.454 92.6708 110.387 92.1524 110.021 91.8705C109.654 91.5887 109.136 91.6563 108.854 92.0225L107.276 94.0734C106.994 94.4396 107.062 94.958 107.428 95.2399C107.794 95.5217 108.313 95.4541 108.594 95.0879L110.172 93.037Z"
      fill="#1D2228"
    />
    <path
      d="M116.547 84.7607C116.828 84.3944 116.761 83.876 116.395 83.5942C116.028 83.3124 115.51 83.3799 115.228 83.7461L113.65 85.797C113.368 86.1632 113.436 86.6817 113.802 86.9635C114.168 87.2453 114.687 87.1778 114.968 86.8115L116.547 84.7607Z"
      fill="#1D2228"
    />
    <path
      d="M106.867 95.976C106.501 95.6942 105.983 95.7617 105.701 96.128L104.123 98.1789C103.841 98.5451 103.908 99.0635 104.275 99.3453C104.641 99.6271 105.159 99.5596 105.441 99.1934L107.019 97.1425C107.301 96.7763 107.233 96.2578 106.867 95.976Z"
      fill="#1D2228"
    />
    <path
      d="M113.385 88.8612C113.667 88.495 113.6 87.9766 113.233 87.6948C112.867 87.413 112.349 87.4805 112.067 87.8467L110.489 89.8976C110.207 90.2638 110.275 90.7823 110.641 91.0641C111.007 91.3459 111.525 91.2784 111.807 90.9121L113.385 88.8612Z"
      fill="#1D2228"
    />
    <path
      d="M103.866 101.241C104.148 100.875 104.08 100.356 103.714 100.075C103.348 99.7928 102.829 99.8604 102.547 100.227L101.927 101.032C102.423 101.297 102.863 101.635 103.359 101.9L103.866 101.241Z"
      fill="#1D2228"
    />
    <path
      d="M99.7712 99.5989L144.241 41.8078C143.948 41.5823 143.655 41.3569 143.362 41.1314C143.069 40.906 142.776 40.6805 142.483 40.4551L98.0133 98.2462C98.3063 98.4716 98.5993 98.6971 98.9655 98.9789C99.1289 99.2212 99.4219 99.4467 99.7712 99.5989Z"
      fill="#1D2228"
    />
    <rect
      x="136.053"
      y="21.0469"
      width="25.6541"
      height="3.06382"
      rx="0.714286"
      transform="rotate(-30 136.053 21.0469)"
      fill="url(#paint3_linear_978_44060)"
    />
    <rect
      x="138.165"
      y="16.9473"
      width="17.8947"
      height="8.05263"
      rx="0.714286"
      transform="rotate(-30 138.165 16.9473)"
      fill="url(#paint4_linear_978_44060)"
    />
    <rect
      x="88.8457"
      y="13.4053"
      width="25.6541"
      height="3.06382"
      rx="0.714286"
      transform="rotate(15 88.8457 13.4053)"
      fill="url(#paint5_linear_978_44060)"
    />
    <rect
      x="93.2383"
      y="12"
      width="17.8947"
      height="8.05263"
      rx="0.714286"
      transform="rotate(15 93.2383 12)"
      fill="url(#paint6_linear_978_44060)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_978_44060"
        x1="-11.8779"
        y1="49.1467"
        x2="122.336"
        y2="72.8823"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000405011" stopColor="white" />
        <stop offset="0.188133" stopColor="#9B9D9F" />
        <stop offset="0.503335" stopColor="#4F5358" />
        <stop offset="0.963542" stopColor="#686B70" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_978_44060"
        x1="159.017"
        y1="49.1467"
        x2="293.231"
        y2="72.8823"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000405011" stopColor="white" />
        <stop offset="0.188133" stopColor="#9B9D9F" />
        <stop offset="0.503335" stopColor="#4F5358" />
        <stop offset="0.963542" stopColor="#686B70" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_978_44060"
        x1="113.004"
        y1="39.5756"
        x2="134.349"
        y2="107.011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.344736" stopColor="white" />
        <stop offset="1" stopColor="#CCCCCC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_978_44060"
        x1="148.88"
        y1="21.0469"
        x2="148.88"
        y2="24.1107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_978_44060"
        x1="147.112"
        y1="16.9473"
        x2="147.112"
        y2="24.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_978_44060"
        x1="101.673"
        y1="13.4053"
        x2="101.673"
        y2="16.4691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_978_44060"
        x1="102.186"
        y1="12"
        x2="102.186"
        y2="20.0526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
    </defs>
  </svg>
)

export default Error404Banner
