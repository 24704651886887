import { NextComponentType } from 'next'
import { FlexBox, theme, Button, trackEvent, TextField } from '@rario/shared-components'
import HomeNavIcon from 'assets/svg/HomeNavIcon'
import Error404Banner from 'assets/Error404Banner'
import Head from 'next/head'
import { useRouter } from 'next/router'

const ErrorNotFound: NextComponentType = () => {
  const { push } = useRouter()
  return (
    <>
      <Head>
        <title>RARIO | Officially Licensed Cricket Digital Player Cards Platform</title>
        <meta
          name="description"
          content="World's first officially licensed digital collectibles platform for cricket enables fans to buy, sell & trade cricket Digital Player Cards of their favorite cricket players and cricket leagues!"
        />
      </Head>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        justifyContent={'center'}
        width="100%"
        padding="0 9px"
        mt="40px"
        height={'60vh'}
      >
        <FlexBox width="100%" justifyContent="center">
          <Error404Banner />
        </FlexBox>
        <TextField
          mt="34px"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight="600"
          fontSize="20px"
          lineHeight="28px"
          textAlign={'center'}
          pb="0"
        >
          Page Not Found
        </TextField>
        <TextField
          mt="8px"
          fontWeight="400"
          fontSize="10px"
          lineHeight="14px"
          color={theme.colors.whites[8]}
          textAlign={'center'}
          pb="0"
        >
          Looks like the page you are looking for is not
          <br /> available or temporarily disabled.
        </TextField>
        <Button
          onClick={() => {
            push('/')
            trackEvent('Section Interacted', 'click', {
              sub_category: '404-homepage_cta',
              category: '404',
              click_type: 'button',
              click_action: 'redirection_to_homepage',
              click_text: 'GO TO HOME',
            })
          }}
          variant="dynamic"
          cornerIndentation="13px"
          name="GO TO HOME"
          rightIcon={<HomeNavIcon fill={theme.colors.primaryText} />}
          fontSize={'14px'}
          mt={'21px'}
        />
      </FlexBox>
    </>
  )
}

export default ErrorNotFound
